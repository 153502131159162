export type OutOfStockResolutionPreferenceType = "substitute" | "refund_item";

export class OutOfStockResolutionPreference {
  private static substitutePreference = new OutOfStockResolutionPreference("substitute", "Replace up to the same value", "The best possible replacement of the same value or less will be selected");
  private static refundPreference = new OutOfStockResolutionPreference("refund_item", "Refund item", "Refund the item if it isn't available");

  public static readonly Default = this.substitutePreference;
  public static readonly Substitute = this.substitutePreference;
  public static readonly Refund = this.refundPreference

  public static readonly preferences = [this.Substitute, this.Refund];

  public static fromType(type: OutOfStockResolutionPreferenceType) {
    switch (type) {
      case "substitute":
        return this.Substitute;
      case "refund_item":
        return this.Refund;
    }
  }

  private constructor(public readonly type: OutOfStockResolutionPreferenceType, public readonly title: string, public readonly description: string) {}
}
